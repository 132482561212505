div.a {
  font-size: 500px;
}

footer {
  box-sizing: border-box;
  height: 100px;
  background-color: #eee;
  border-top: 1px solid #e0e0e0;
  padding-top: 35px;
}

.listitem {
    transform: scale(1.3);
    -webkit-transform-origin-x: 0;
}
