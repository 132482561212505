/* .App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
} */

.circle {
  background: #d5d5d5;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 40px auto 40px;
  position: relative;
  /* top: 200px; left: 200px; for example purposes only */
}

.circle-hold {
  position: absolute;
  left: 90px; /* calculate circle width / 2 - .square width / 2 */
  top: 90px; /* calculate circle height / 2 - .square height / 2 */
  /* transform: translate(-40%, -50%); */
}

.square {
  position: absolute;
  -webkit-transition: all 2s linear;
  -moz-transition: all 2s linear;
  transition: transform 2s linear;
  width: 30px;
  height: 20px;
  /* border-radius: 50%; */
  color: black;
  left: 0;
}

.fix{
    position:absolute;
    bottom:0px;
    left:0%;
}
